import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostLink from "../components/post-link"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return( <Layout>
    <div id="indexGrid">
      <div className="blog-post-container">
        <SEO title="Home" />
        <div style={{paddingTop: `50px`, textAlign: `center`}}>
          {Posts}
        </div>
        <div style={{gridColumn: 2}}>
          {/* <div style={{ maxWidth: `400px`, margin: `0 auto`, zIndex: 1000 }}>
            <Link  to="/">
              <Image />
            </Link>
          </div> */}
          {/* <div className="navigation" style={{ margin: `0 auto`, zIndex: 20000}}>
            <Sidebar></Sidebar>
          </div> */}
        </div>
      </div>
    </div>
    <footer id="indexFooter">
         <p>
            © {new Date().getFullYear()}, Built by
            {` `}
            <a href="https://kwinten.me">Kwinten Delrue</a> with Gatsby
          </p>
     </footer>
  </Layout>)
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`